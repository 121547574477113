

















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import SectionHeader from './header.vue';
import restApi from './rest-api';

@Component({ components: { SectionHeader } })
export default class Lightbox extends Vue {

    @Prop()
    private readonly title: string;

    @Prop({ required: true })
    private readonly image: string;

    imageFormat: string = 'png500500';

    private readonly imageWidth: number = 100;

    private show: boolean = false;

    private readonly downloadOptions: any[] = [
        { text: 'PNG (200x200)', value: 'png200200' },
        { text: 'PNG (300x300)', value: 'png300300' },
        { text: 'PNG (500x500)', value: 'png500500' },
        { text: 'SVG', value: 'svg' }
    ];

    created() {
        console.log('Adding resize handler');
        window.addEventListener('resize', this.onResize);
    }

    destroyed() {
        console.log('Removing resize handler');
        window.removeEventListener('resize', this.onResize);
    }

    @Watch('image')
    private onImageChanged(newValue: string | undefined) {
        this.show = !!newValue;
        if (this.show) {
            document.addEventListener('keydown', this.keyHandler);
        }
    }

    private hide() {
        console.log('hidden!');
        this.show = false;
        this.$emit('hide');
    }

    private onResize() {
        // const screenWidth: number = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        // const horizontalSpacing: number = 100;
        // this.imageWidth = Math.min(600, screenWidth - horizontalSpacing);
        // console.log('imageWidth now: ' + this.imageWidth);
    }

    keyHandler(e: KeyboardEvent) {
        if (e.key === 'Escape') {
            document.removeEventListener('keydown', this.keyHandler);
            this.hide();
        }
    }

    saveImage() {

        let width: number | undefined;
        let height: number | undefined;

        switch (this.imageFormat) {
            case 'png200200':
                width = height = 200;
                break;
            case 'png300300':
                width = height = 300;
                break;
            case 'png500500':
                width = height = 500;
                break;
        }

        restApi.downloadCompoundImage(this.title, width, height);
    }
}
