









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Partner } from './rak';

@Component
export default class PartnerBadge extends Vue {

    @Prop({ required: true })
    private readonly partner: Partner;

    getImageUrl(imageFileName: string): string {
        return `/img/partners/${imageFileName}`;
    }
}
