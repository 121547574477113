














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ActionableCardTitle extends Vue {

    @Prop({ required: true })
    private readonly title: string;

    @Prop()
    private readonly subTitle: string | undefined;
}
