























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ConfirmModal from '../confirm-modal.vue';
import { BlogPost, ErrorResponse } from '../rak';

@Component({ components: { ConfirmModal } })
export default class BlogManagerPostNameCell extends Vue {

    @Prop({ required: true })
    private readonly post: BlogPost;

    deletePost(postId: number) {
        this.$emit('deletePost', this.post);
    }

    editPost(postId: number) {
        this.$emit('editPost', this.post);
    }

    get beautifiedCreateDate(): string {
        return new Date(this.post.createDate!).toLocaleDateString();
    }

    viewPost(postId: number) {
        alert('Not yet implemented');
    }
}
