

























































































































import Vue from 'vue';
import { Route } from 'vue-router';
import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import NavbarPill from './navbar-pill.vue';
import LoginModal from '../login-modal.vue';
import FeedbackModal from '../feedback-modal.vue';
import RakUtil from '../util';
import restApi from '../rest-api';
import Toaster from '../toaster';
import SmallScreenMenu from './small-screen-menu.vue';

@Component({ components: { SmallScreenMenu, NavbarPill, LoginModal, FeedbackModal } })
export default class Navbar extends Vue {

    openCompounds: string[] = [];
    showLogin: boolean = false;
    showFeedback: boolean = false;
    showSmallScreenMenu: boolean = false;

    private isActiveTab(tabName: string): boolean {
        return RakUtil.isActiveTab(this.$route, tabName);
    }

    private setActiveTab(tabName: string) {
        this.$router.push({ name: tabName });
    }

    private close($event: string) {

        const index: number = this.openCompounds.indexOf($event);
        if (index > -1) {
            this.openCompounds.splice(index, 1);
        }

        // Characters that we allow in a compound name, but would break our regex check for the
        // "previous" tab below
        const regexSafeCompoundName: string = $event.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        const tabNameRegex: RegExp = new RegExp(regexSafeCompoundName + '$');
        if (this.$route.fullPath && !!decodeURIComponent(this.$route.fullPath).match(tabNameRegex)) {
            console.log('Going back');
            this.$router.back();
        }
    }

    created() {

        // If the user bookmarked a compound, be sure it has an open tab
        this.possiblyOpenCompoundTab(this.$route);
    }

    private logout() {
        restApi.logout()
            .then(() => {

                Toaster.success(`See you next time, ${this.$store.state.user}!`);

                // TODO: This flashes the "no access" stuff on the admin tab for a second.
                // Conver to an action to fix this
                if (this.$router.currentRoute.name !== 'home') {
                    this.$router.push({ name: 'home' });
                }
                this.$store.commit('setUser', null);
            });
    }

    @Watch('$route')
    private onRouteChanged(to: Route, from: Route) {
        this.possiblyOpenCompoundTab(to);
    }

    private possiblyOpenCompoundTab(route: Route) {
        if (route.path.match(/\/compound\/[\w()]+/)) {
            // tslint:disable:no-string-literal
            const compound: string = route.params['id'];
            console.log(`Compound opened; adding pill for it if one doesn't yet exist: ${compound}`);
            if (this.openCompounds.indexOf(compound) === -1) {
                this.openCompounds.push(compound);
            }
        }
    }

    private showCompoundDetails(compound: string) {
        this.$router.push({ name: 'compound', params: { id: compound }});
    }

    private showPartners() {
        this.$router.push({ name: 'partners' });
    }
}
