































import Vue from 'vue';
import Component from 'vue-class-component';
import SectionHeader from './header.vue';
import BlogPostWidget from './blog-post.vue';
import { BlogPost, PagedDataRep } from './rak';
import restApi from './rest-api';
import { Route } from 'vue-router';

@Component({ components: { SectionHeader, BlogPostWidget } })
export default class Blog extends Vue {

    private postCount: number = -1;
    private postStart: number = 0;
    private postEnd: number = 0;
    private postTotal: number = 0;

    private blogPosts: BlogPost[] = [];

    beforeRouteEnter(to: Route, from: Route, next: any) {
        // We don't have access to 'this' since this is called before the component is
        // realized.  We must do initialization in a callback to next()
        next((vue: Blog) => {
            vue.loadBlogPosts();
        });
    }

    private loadBlogPosts() {

        console.log('Loading blog posts...');
        this.postCount = -1;

        restApi.getBlogPosts(0, 1000, {}, 'createDate,desc')
            .then((posts: PagedDataRep<BlogPost>) => {
                this.blogPosts = posts.data;
                this.postCount = posts.count;
                this.postStart = posts.start + 1;
                this.postEnd = this.postStart + this.postCount - 1;
                this.postTotal = posts.total;
            })
            .catch(() => {
                this.postCount = 0;
            });
    }
}
