














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SelectItem } from '../rak';

@Component
export default class ImportPreviewTableFilter extends Vue {

    @Prop({ required: true })
    private readonly disabled: boolean;

    private readonly choices: SelectItem[] = [
        { text: '(No filter)', value: 'none' },
        { text: 'New records', value: 'new' },
        { text: 'Modified records', value: 'modified' },
        { text: 'Unmodified records', value: 'unmodified' }
    ];

    fireChangeEvent($event: any) {
        this.$emit('change', $event);
    }
}
