

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Toaster from './toaster';

@Component
export default class ImportCompounds extends Vue {

    /**
     * "value" facilitates v-model support
     */
    @Prop({ required: true })
    value: File | null;

    onDragOver(e: DragEvent) {
        e.dataTransfer!.dropEffect = 'copy';
    }

    onDrop(e: DragEvent) {

        const files: FileList = e.dataTransfer!.files;
        if (files.length === 0) {
            Toaster.error('The dropped item is not a file');
        }
        else if (files.length > 1) {
            Toaster.error('Only one file can be imported at a time');
        }
        else {
            this.$emit('input', files[0]);
        }
    }

    private onFileChanged() {

        const fileInput: HTMLInputElement = this.$refs.fileInput as HTMLInputElement;
        const file: File = fileInput.files![0];

        this.$emit('input', file);
    }

    private onSelectFileButtonClicked() {
        const input: HTMLInputElement = this.$refs.fileInput as HTMLInputElement;
        input.click();
    }
}
