









































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Audit, PagedDataRep, VueDataTableOptions } from '../rak';
import SectionHeader from '../header.vue';
import AuditActions from './audit-actions';
import restApi from '../rest-api';
import rakUtil from '../util';
import debounce from 'debounce';

@Component({ components: { SectionHeader } })
export default class AuditHistory extends Vue {

    private totalItems: number = 0;

    private items: Audit[] = [];

    private loading: boolean = true;

    private actions: any = [];
    private maxAllowedDate: string = '';

    actionFilter: string = '';
    ipAddressFilter: string = '';
    userFilter: string = '';
    successFilter: string = 'any';
    fromDateFilter: string = '';
    showFromDate: boolean = false;
    toDateFilter: string = '';
    showToDate: boolean = false;
    tableOptions: VueDataTableOptions = {
        page: 1,
        itemsPerPage: 20,
        sortBy: [ 'createDate' ],
        sortDesc: [ true ],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
    };

    created() {

        this.actions = AuditActions.getLabelValues();
        this.maxAllowedDate = new Date().toISOString();
        this.maxAllowedDate = this.maxAllowedDate.substring(0, this.maxAllowedDate.indexOf('T'));

        this.debouncedReloadTable = debounce(this.debouncedReloadTable, 750);
    }

    private debouncedReloadTable() {
        return this.reloadTable();
    }

    getAuditActionLabel(action: string): string {
        return AuditActions.getLabel(action);
    }

    getDisplayDate(iso8601Date: string): string {
        return rakUtil.getDisplayDate(iso8601Date, true);
    }

    get headers(): any[] /*VTableHeader[]*/ {

        return [
            { text: 'Date', value: 'createDate' },
            { text: 'User', value: 'userName' },
            { text: 'Action', value: 'action' },
            { text: 'IP Address', value: 'ipAddress' },
            { text: 'Successful?', value: 'success' }
        ];
    }

    mounted() {
        this.reloadTable();
    }

    reloadTable() {

        this.loading = true;
        const options: VueDataTableOptions = this.tableOptions;

        let sort: string = '';
        for (let i: number = 0; i < options.sortBy.length; i++) {
            const sortCol: string = options.sortBy[i];
            const sortDir: string = options.sortDesc[i] ? 'desc' : 'asc';
            sort += `${sortCol},${sortDir}`;
            if (i < options.sortBy.length - 1) {
                sort += '&sort=';
            }
        }

        const filters: any = {
            userName: this.userFilter,
            action: this.actionFilter,
            ipAddress: this.ipAddressFilter,
            success: this.successFilter,
            fromDate: this.fromDateFilter ? this.fromDateFilter : null,
            toDate: this.toDateFilter ? this.toDateFilter : null
        };

        return restApi.getAuditRecords(options.page - 1, options.itemsPerPage, filters, sort)
            .then((pagedData: PagedDataRep<Audit>) => {
                this.items = pagedData.data;
                this.totalItems = pagedData.total;
                this.loading = false;
                return pagedData;
            });
    }

    @Watch('tableOptions')
    private onTablePagingOrSortingChanged(newOptions: VueDataTableOptions) {
        return this.reloadTable();
    }
}
