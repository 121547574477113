import { render, staticRenderFns } from "./small-screen-menu.vue?vue&type=template&id=bed42494&"
import script from "./small-screen-menu.vue?vue&type=script&lang=ts&"
export * from "./small-screen-menu.vue?vue&type=script&lang=ts&"
import style0 from "./small-screen-menu.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VIcon,VImg,VList,VListItem,VListItemAction,VListItemGroup,VListItemIcon,VListItemTitle,VSubheader})
