




















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import SectionHeader from './header.vue';
import CompoundNetwork from './compound-network.vue';
import restApi from './rest-api';
import { Compound, PagedDataRep, SearchFilter } from './rak';
import ActionableCardTitle from './actionable-card-title.vue';

const MAX_COMPOUND_COUNT: number = 6;

@Component({ components: { ActionableCardTitle, SectionHeader, CompoundNetwork } })
export default class CompoundNetworkTab extends Vue {

    private loading: boolean = false;
    percentControl: string = '20';
    kd: string = '';
    private compounds: Compound[] = [];
    private networkCompounds: Compound[] = [];
    search: string = '';
    private selectedCompounds: Compound[] = [];
    private formInvalid: boolean = true;
    physicsEnabled: boolean = true;

    private compoundDropdownRules(): Function[] {

        return [

            (value: Compound[]) => {
                return value.length <= MAX_COMPOUND_COUNT || `Can't render more than ${MAX_COMPOUND_COUNT} compounds`;
            }
        ];
    }

    private numericValidationRules(max: number): Function[] {

        return [

            (value: string) => {
                return CompoundNetworkTab.isEmpty(value) || !isNaN(parseFloat(value)) || 'Must be a number';
            },

            (value: string) => {
                if (!parseFloat(value)) {
                    return true; // Will be caught by the rule above
                }
                return +value >= 0 && +value <= max || `Must be between 0 and ${max}`;
            }
        ];
    }

    private static isEmpty(text: string): boolean {
        // text might be a number
        return !text || !text.trim || !text.trim().length;
    }

    @Watch('search')
    private onSearchChanged(newVal: string) {
        if (newVal) {
            this.updateDropdown();
        }
    }

    removeCompound(compound: Compound) {
        this.selectedCompounds = this.selectedCompounds.filter((c2: Compound) => {
            return c2.compoundName !== compound.compoundName;
        });
        // form.validate() needs one update cycle to see new value of this.selectedCompounds
        setInterval(() => {
            this.updateSubmitButtonState();
        }, 0);
    }

    rerender() {
        this.networkCompounds = this.selectedCompounds.slice();
    }

    /**
     * Fires when new values are added.  Values being removed by x-ing out a Chip do not
     * call this method; see removeCompound() for that code path.
     *
     * @param newValue The new array of selected compounds.
     */
    selectedCompoundsChanged(newValue: Compound[]) {
        this.updateSubmitButtonState();
    }

    private updateSubmitButtonState() {
        console.log('Updating submit button...');
        this.formInvalid = !(this.$refs.form as any).validate();
    }

    private updateDropdown() {

        this.loading = true;

        const filter: SearchFilter = {
            inhibitor: this.search,
            kinase: '',
            activity: '',
            kd: '',
            activityOrKd: 'kd'
        };

        restApi.getCompounds(0, 10, filter)
            .then((pagedData: PagedDataRep<Compound>) => {
                this.compounds = pagedData.data;
                this.loading = false;
                return pagedData;
            });
    }
}
