








import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({ components: { } })
export default class ExpectedColumnListing extends Vue {

    @Prop({ required: true })
    private readonly columnNames: string[];
}
