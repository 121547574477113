







































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Compound } from '../rak';
import RakUtil from '@/util';

@Component
export default class CompoundDetailsCard extends Vue {

    @Prop({ required: true })
    private readonly compound: Compound;

    get compoundImageUrl(): string {
        return RakUtil.getCompoundImageUrl(this.compound);
    }

    get reference() {

        if (this.compound.primaryReference) {
            if (this.compound.primaryReferenceUrl) {
                return `<a href="${this.compound.primaryReferenceUrl}" target="_blank" ` +
                    `rel="noopener noreferrer">${this.compound.primaryReference}</a>`;
            }
            return this.compound.primaryReference;
        }

        return '';
    }

    onImageClicked() {
        this.$store.commit('setLightboxImage', this.compoundImageUrl);
        this.$store.commit('setLightboxTitle', this.compound ? this.compound.compoundName : 'Unknown');
    }
}
