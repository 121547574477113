














import Vue from 'vue';
import Component from 'vue-class-component';
import SectionHeader from './header.vue';
import PartnerBadge from './partner.vue';
import { PagedDataRep, Partner } from './rak';
import restApi from './rest-api';

@Component({ components: { PartnerBadge, SectionHeader } })
export default class Partners extends Vue {

    private partners: Partner[] = [];

    private loadPartners() {
        restApi.getPartners()
            .then((partners: PagedDataRep<Partner>) => {
                this.partners = partners.data;
            });
    }

    mounted() {
        this.loadPartners();
    }
}
