








































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class SmallScreenMenu extends Vue {

    @Prop({ required: true })
    openCompounds!: string[];

    private close() {
        this.$emit('close');
    }

    private closeCompound(compound: string) {
        this.close();
        this.$emit('closeCompound', compound);
    }

    private setActiveTab(tabName: string) {
        this.close();
        this.$router.push({ name: tabName });
    }

    private showCompoundDetails(compound: string) {
        this.close();
        this.$emit('showCompoundDetails', compound);
    }

    private showFeedback() {
        this.close();
        this.$emit('feedback');
    }
}
