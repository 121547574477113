











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import RakUtil from '../util';

@Component
export default class NavbarPill extends Vue {

    @Prop({ required: true })
    compound: string;

    close(e: MouseEvent) {
        this.$emit('close', this.compound);
    }

    beforeDestroy() {
        const closeButton: Element = this.$el.querySelector('.close-icon')!;
        closeButton.removeEventListener('mouseover', this.onCloseButtonMouseOver);
        closeButton.removeEventListener('mouseout', this.onCloseButtonMouseOut);
    }

    created() {
        this.onCloseButtonMouseOver = this.onCloseButtonMouseOver.bind(this);
        this.onCloseButtonMouseOut = this.onCloseButtonMouseOut.bind(this);
    }

    isActiveTab(): boolean {
        return RakUtil.isActiveTab(this.$route, `/compound/${this.compound}`);
    }

    mounted() {
        // We must programmatically change style of "parent" div when close button
        // is armed unfortunately.
        const closeButton: Element = this.$el.querySelector('.close-icon')!;
        closeButton.addEventListener('mouseover', this.onCloseButtonMouseOver);
        closeButton.addEventListener('mouseout', this.onCloseButtonMouseOut);
    }

    onCloseButtonMouseOver() {
        const elem: HTMLElement = this.$el.getElementsByClassName('navbar-pill')[0] as HTMLElement;
        elem.classList.add('closeButtonArmed');
    }

    onCloseButtonMouseOut() {
        const elem: HTMLElement = this.$el.getElementsByClassName('navbar-pill')[0] as HTMLElement;
        elem.classList.remove('closeButtonArmed');
    }

    showCompound() {
        this.$emit('showCompoundDetails', this.compound);
    }
}
