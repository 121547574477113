



























import Vue from 'vue';
import Component from 'vue-class-component';
import LazyDropdown from '../lazy-dropdown.vue';
import { Kinase, PagedDataRep } from '../rak';
import ActivityOrKdField from './activity-or-kd-field.vue';

@Component({ components: { ActivityOrKdField, LazyDropdown } })
export default class SearchFilters extends Vue {

    get filters() {
        return this.$store.state.filters;
    }

    get kinaseDropdownLabel(): string {
        return this.filters.activityOrKd === 'percentControl' ? 'Or by kinase and activity' : 'Or by kinase and Kd';
    }

    private readonly kinaseQueryParams: any = { size: 1000 };

    private kinaseResponseTransformer(response: PagedDataRep<Kinase>): any[] {
        const choices: any[] = response.data.map((kinase: Kinase) => {
            const entrez: string = kinase.entrezGeneSymbol;
            return { entrezGeneSymbol: entrez, responseValueField: entrez };
        });
        //choices.unshift({ entrezGeneSymbol: '', responseValueField: '' });
        return choices;
    }
}
