















































import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Home extends Vue {

    created() {
        setTimeout(() => {
            document.getElementsByClassName('parallax')[0].classList.remove('hidden');
        }, 100);
    }
}
