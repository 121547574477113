var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":""}},[_c('v-row',{staticClass:"feedback-wrapper",attrs:{"wrap":""}},[_c('section-header',[_vm._v("Feedback")]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('p',[_vm._v(" Feedback as entered by users. Hopefully at least some of this can be converted into bug reports or feature requests. ")]),_c('p',[_vm._v(" Click on a row to see more detail about the feedback. ")])])]),_c('v-col',{staticClass:"feedback-button-section",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"feedback-button",attrs:{"disabled":_vm.isReplyDisabled()},on:{"click":_vm.onReply}},[_vm._v(" Reply ")]),_c('v-btn',{staticClass:"feedback-button",attrs:{"disabled":_vm.loading || _vm.selectedFeedback.length !== 1},on:{"click":_vm.onCreateTicket}},[_vm._v(" Create GitHub ticket ")]),_c('v-btn',{staticClass:"feedback-button",attrs:{"disabled":_vm.loading || _vm.selectedFeedback.length === 0},on:{"click":_vm.onDelete}},[_vm._v(" Delete ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","show-select":"","server-items-length":_vm.totalItems,"options":_vm.tableOptions,"loading":_vm.loading,"multi-sort":"","show-expand":"","single-expand":true,"footer-props":{ 'items-per-page-options': [ 10, 20, 50 ] }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.selected",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"primary":"","hide-details":""},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.linkifyEmail(item))}})]}},{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createDate).toLocaleString())+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('span',{staticClass:"feedback-body"},[_vm._v(_vm._s(item.body || '(no feedback data)'))])])]}}]),model:{value:(_vm.selectedFeedback),callback:function ($$v) {_vm.selectedFeedback=$$v},expression:"selectedFeedback"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showConfirmDeleteModal),callback:function ($$v) {_vm.showConfirmDeleteModal=$$v},expression:"showConfirmDeleteModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Confirm Delete Feedback ")]),_c('v-card-text',[_vm._v(" Are you sure you want to delete the selected feedback items? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteSelectedFeedback.apply(null, arguments)}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();_vm.showConfirmDeleteModal = false}}},[_vm._v("No")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }