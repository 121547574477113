







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({ components: { } })
export default class ActivityOrKdToggleButton extends Vue {

    private suffix: string = '%';

    private toggle() {
        this.suffix = this.suffix === '%' ? 'nM' : '%';
        this.$store.commit('setFilterType', this.suffix === '%' ? 'percentControl' : 'kd');
    }
}
