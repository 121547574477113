































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SectionHeader from '@/header.vue';
import ResultTable from './result-table.vue';
import ResultList from './result-list.vue';
import CompoundDetailsCard from './compound-details-card.vue';
import EditCompoundModal from './edit-compound-modal.vue';
import restApi from '@/rest-api';
import { ActivityProfile, Compound, ErrorResponse, PagedDataRep } from '@/rak';
import unauthorized from '@/admin/unauthorized.vue';

@Component({ components: {
        CompoundDetailsCard,
        ResultTable,
        ResultList,
        SectionHeader,
        EditCompoundModal,
        unauthorized
} })
export default class CompoundView extends Vue {

    @Prop({ required: true })
    private readonly id: string;

    private compound: Compound | null = {
        compoundName: '',
        chemotype: '',
        s10: ''
    };

    private chartData: any[] | null = null;

    private showEditCompound: boolean = false;
    private authorized: boolean = true;

    compoundUpdated(newCompound: Compound) {
        //(this.$refs.compoundDetailsCard as CompoundDetailsCard).refresh();
        this.compound = newCompound;
    }

    edit() {
        this.showEditCompound = true;
    }

    get gridFilters(): any {
        return {
            inhibitor: this.id, //this.filters.inhibitor,
            kinase: '', //this.filters.kinase,
            activity: '' //this.filters.activity
        };
    }

    mounted() {

        const sort: any = null;

        restApi.getCompound(this.id)
            .then((compound: Compound) => {

                this.compound = compound;

                return restApi.getActivityProfiles(0, 10000, this.gridFilters, sort)
                    .then((allData: PagedDataRep<ActivityProfile>) => {
                        const activityProfiles: ActivityProfile[] = allData.data;
                        this.chartData = activityProfiles
                            .filter((profile: ActivityProfile) => {
                                return profile.percentControl <= 100;
                            })
                            .sort((a: ActivityProfile, b: ActivityProfile) => {
                                if (b.percentControl < a.percentControl) {
                                    return -1;
                                }
                                return b.percentControl > a.percentControl ? 1 : 0;
                            })
                            .map((profile: ActivityProfile) => {
                                return [
                                    profile.kinase.discoverxGeneSymbol,
                                    profile.percentControl
                                ];
                            });
                    });
            })
            .catch((errorResponse: ErrorResponse) => {
                this.authorized = false;
            });
    }
}
