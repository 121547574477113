













































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import axios, { AxiosResponse } from 'axios';
import debounce from 'debounce';
import { PagedDataRep, VueDataTableOptions } from '@/rak';

export interface ColumnInfo {
    columnName: string;
    columnId: string;
    isCompound?: boolean;
}

/**
 * A table showing information about compounds.  This is really just a wrapper around our table
 * component with niceties built in for tables showing compound-related information.
 */
@Component
export default class CompoundTable extends Vue {

    @Prop({ required: true })
    private readonly url: string;

    @Prop({ required: true })
    private readonly columnInfo: ColumnInfo[];

    @Prop({ default: false })
    private readonly dense: boolean;

    private totalItems: number = 0;

    private items: any[] = [];

    private loading: boolean = true;

    tableOptions: VueDataTableOptions = {
        page: 1,
        itemsPerPage: 10,
        sortBy: [ 'compoundName' ],
        sortDesc: [ false ],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
    };

    private getCompoundLink(compoundName: string) {
        return `#/compound/${compoundName}`;
    }

    created() {
        this.debouncedReloadTable = debounce(this.debouncedReloadTable, 750);
    }

    private get createHeaders() {

        const headers: any = [];

        this.columnInfo.forEach((colInfo: ColumnInfo) => {

            const header: any = { text: colInfo.columnName, value: colInfo.columnId };
            if (colInfo.columnId === 's10') {
                header.align = 'right';
            }

            headers.push(header);
        });

        return headers;
    }

    /**
     * A debounced call to <code>reloadTable()</code>.  We must do this because you
     * cannot debounce a watch function (somehow it's "too late") so we have to be a
     * little indirect to ensure debouncing.
     */
    private debouncedReloadTable(newUrl: string) {
        console.log(`Reloading table due to url change to: ${newUrl}`);
        return this.reloadTable();
    }

    /**
     * Returns the CSS classes to apply to a cell for the given column.
     *
     * @param {string} columnId
     * @returns {string}
     */
    private getClassesForRow(columnId: string): string | undefined {
        // TODO: Extract this hard-coded knowledge of numeric columns somewhere else
        if ('s10' === columnId) {
            return 'text-right';
        }
        return undefined;
    }

    reloadTable() {

        this.loading = true;
        const options: VueDataTableOptions = this.tableOptions;

        let sort: string = '';
        for (let i: number = 0; i < options.sortBy.length; i++) {
            const sortCol: string = options.sortBy[i];
            const sortDir: string = options.sortDesc[i] ? 'desc' : 'asc';
            sort += `${sortCol},${sortDir}`;
            if (i < options.sortBy.length - 1) {
                sort += '&sort=';
            }
        }

        const firstParamChar: string = this.url.indexOf('?') > -1 ? '&' : '?';

        let url: string = `${this.url}${firstParamChar}page=${options.page - 1}&size=${options.itemsPerPage}`;
        if (sort) {
            url += `&sort=${sort}`;
        }

        return axios.get(url)
            .then((response: AxiosResponse<PagedDataRep<any>>) => {
                const pagedData: PagedDataRep<any> = response.data;
                this.items = pagedData.data;
                this.totalItems = pagedData.total;
                this.loading = false;
                return pagedData;
            });
    }

    @Watch('tableOptions')
    private onTablePagingOrSortingChanged(newOptions: VueDataTableOptions) {
        return this.reloadTable();
    }

    @Watch('url')
    private onUrlChanged(newValue: string) {
        return this.debouncedReloadTable(newValue);
    }
}
