



































































































import Vue from 'vue';
import Component from 'vue-class-component';
import SectionHeader from '../header.vue';
import { ErrorResponse, Feedback, PagedDataRep, VueDataTableOptions } from '../rak';
import { Watch } from 'vue-property-decorator';
import restApi from '../rest-api';
import Toaster from '../toaster';
import rakUtil from '../util';

@Component({ components: { SectionHeader } })
export default class FeedbackManager extends Vue {

    selectedFeedback: any[] = [];

    private totalItems: number = 0;

    private items: Feedback[] = [];

    private loading: boolean = true;

    private showConfirmDeleteModal: boolean = false;

    tableOptions: VueDataTableOptions = {
        page: 1,
        itemsPerPage: 20,
        sortBy: [ 'createDate' ],
        sortDesc: [ true ],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
    };

    get headers(): any[] /*VTableHeader[]*/ {

        return [
            { text: 'E-mail', value: 'email' },
            { text: 'Title', value: 'title' },
            { text: 'Date', value: 'createDate' }
        ];
    }

    isReplyDisabled(): boolean {
        return this.loading || this.selectedFeedback.length !== 1 || !this.selectedFeedback[0].email;
    }

    /**
     * Creates a <code>mailto:</code> URL to response to a feedback item.  It is assumed
     * that the feedback's email has previously been verified as  non-<code>null</code>.
     *
     * @param {Feedback} feedback The feedback.
     * @returns {string} The <code>mailto:</code> URL.
     */
    private createMailtoUrl(feedback: Feedback): string {
        const email: string = feedback.email!;
        const subject: string = `Random Acts of Kinase - Feedback &quot;${encodeURIComponent(feedback.title)}&quot;`;
        return `mailto:${email}?subject=${subject}`;
    }

    deleteSelectedFeedback() {
        this.showConfirmDeleteModal = false;
        this.loading = true;
        this.deleteSelectedFeedbackImpl(this.selectedFeedback.slice(), 0);
    }

    private deleteSelectedFeedbackImpl(feedbacks: Feedback[], index: number) {

        const feedback: Feedback = feedbacks[index];

        restApi.deleteFeedback(feedback.id!)
            .then(() => {
                if (index < feedbacks.length - 1) {
                    this.deleteSelectedFeedbackImpl(feedbacks, index + 1);
                }
                else {
                    this.selectedFeedback.length = 0;
                    this.reloadTable();
                    Toaster.success('Feedback successfully deleted');
                }
            })
            .catch((error: ErrorResponse) => {
                this.reloadTable();
                Toaster.error('An error occurred deleting one or more feedback entries');
            });
    }

    linkifyEmail(feedback: Feedback): string {

        if (!feedback.email) {
            return '';
        }
        return `<a href="${this.createMailtoUrl(feedback)}">${feedback.email}</a>`;
    }

    onCreateTicket() {
        Toaster.error('Not yet implemented');
    }

    onDelete() {
        this.showConfirmDeleteModal = true;
    }

    onReply() {
        const feedback: Feedback = this.selectedFeedback[0];
        rakUtil.programmaticallyClickLink(this.createMailtoUrl(feedback));
    }

    reloadTable() {

        this.loading = true;
        const options: VueDataTableOptions = this.tableOptions;

        let sort: string = '';
        for (let i: number = 0; i < options.sortBy.length; i++) {
            const sortCol: string = options.sortBy[i];
            const sortDir: string = options.sortDesc[i] ? 'desc' : 'asc';
            sort += `${sortCol},${sortDir}`;
            if (i < options.sortBy.length - 1) {
                sort += '&sort=';
            }
        }

        return restApi.getFeedback(options.page - 1, 10000, {}, sort)
            .then((pagedData: PagedDataRep<Feedback>) => {
                this.items = pagedData.data;
                this.totalItems = pagedData.total;
                this.loading = false;
                return pagedData;
            });
    }

    @Watch('tableOptions')
    private onTablePagingOrSortingChanged(newOptions: VueDataTableOptions) {
        return this.reloadTable();
    }
}
