




























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import unauthorized from './unauthorized.vue';

@Component({ components: { unauthorized } })
export default class AdminHome extends Vue {

    navDrawerOpen: boolean = true;

    importSubMenuExpanded: boolean = true;
}
