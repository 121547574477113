























































































import restApi from '../rest-api';
import { PagedDataRep, VueDataTableOptions } from '@/rak';
import NewTabLink from '@/new-tab-link.vue';

export default {
    name: 'result-list',
    components: {NewTabLink},
    computed: {
        headers: function() {
            return [
                { text: 'Compound', value: 'compoundName' },
                { text: '% Control', value: 'percentControl', align: 'right' },
                { text: 'Concentration (nM)', value: 'compoundConcentration', align: 'right' },
                { text: 'Kinase DiscoverX', value: 'kinase.discoverxGeneSymbol' },
                { text: 'Kinase Entrez', value: 'kinase.entrezGeneSymbol' },
                { text: 'Kinase Nanosyn', value: 'kinase.nanosynGeneSymbol' },
                { text: 'K<sub>d</sub>', value: 'kd' }
            ];
        },
        sortOptions() {
            return this.headers.map((header: any) => header.value);
        }
    },
    props: {
        //inhibitor: String,
        filters: {
            type: Object,
            required: true
        }
    },
    data: function() {
        return {
            ascending: true,
            totalItems: 0,
            items: [],
            loading: true,
            tableOptions: {
                page: 1,
                itemsPerPage: 5,
                sortBy: 'percentControl',
                groupBy: [],
                groupDesc: [],
                mustSort: false
            }
        };
    },
    watch: {
        'filters.inhibitor': function(newFilter: string) {
            console.log('inhibitor changed');
            this.reloadTable();
        },
        'filters.kinase': function(newFilter: string) {
            console.log('kinase changed');
            this.reloadTable();
        },
        'filters.activity': function(newFilter: string) {
            console.log('activity changed');
            this.reloadTable();
        },

        tableOptions: {
            handler() {
                this.reloadTable();
            },
            deep: true
        },

        ascending() {
            this.reloadTable();
        }
    },
    methods: {

        addSuppliedFilters: function(data: any) {
            if (this.filters.inhibitor) {
                data.compound = this.filters.inhibitor;
            }
            if (this.filters.activity) {
                data.activity = this.filters.activity;
            }
            if (this.filters.kinase) {
                data.kinase = this.filters.kinase;
            }
        },

        reloadTable: function() {

            this.loading = true;
            const options: VueDataTableOptions = this.tableOptions;
            const dir: string = this.ascending ? 'asc' : 'desc';
            const sort: string = `&sort=${options.sortBy},${dir}`;

            return restApi.getActivityProfiles(options.page - 1, options.itemsPerPage, this.filters, sort)
                .then((pagedData: PagedDataRep<any>) => {
                    this.items = pagedData.data;
                    this.totalItems = pagedData.total;
                    this.loading = false;
                    return pagedData;
                });
        }
    }
};
