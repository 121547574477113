















































import restApi from '../rest-api';
import Blazy from 'blazy';
import { Compound, PagedDataRep, VueDataTableOptions } from '@/rak';
import RakUtil, { DEFAULT_MOLECULE_SVG } from '@/util';

export default {
    name: 'search-result-table',
    props: {
        //inhibitor: String,
        filters: {
            type: Object,
            required: true
        }
    },
    data: function() {
        return {
            blazy: null,
            totalItems: 0,
            items: [],
            loading: true,
            tableOptions: {
                page: 1,
                itemsPerPage: 20,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false
            }
        };
    },
    watch: {
        tableOptions: {
            handler(newValue: any) {
                console.log('Table options changed');
                this.reloadTable();
            },
            deep: true
        },
        filters: {
            handler(newValue: any) {
                console.log('Filterable property changed');
                this.tableOptions.page = 1; // Always get first page if filters changed
                this.reloadTable();
            },
            deep: true
        }
    },
    methods: {

        getCompoundImage: function(compound: Compound) {
            return RakUtil.getCompoundImageUrl(compound);
        },

        getCompoundUrl: function(compoundName: string) {
            return `#/compound/${encodeURIComponent(compoundName)}`;
        },

        getDisplayS10: function(s10: string | null | undefined) {
            return s10 || '?';
        },

        openCompound: function(compoundName: string) {
            window.location.href = this.getCompoundUrl(compoundName);
        },

        onImageClicked: function(compound: Compound) {
            this.$store.commit('setLightboxImage', this.getCompoundImage(compound));
            this.$store.commit('setLightboxTitle', compound.compoundName);
        },

        reloadTable: function() {

            this.loading = true;
            const options: VueDataTableOptions = this.tableOptions;

            let sort: string = '';
            for (let i: number = 0; i < options.sortBy.length; i++) {
                const sortCol: string = options.sortBy[i];
                const sortDir: string = options.sortDesc[i] ? 'desc' : 'asc';
                sort += `${sortCol},${sortDir}`;
                if (i < options.sortBy.length - 1) {
                    sort += '&sort=';
                }
            }

            return restApi.getCompounds(options.page - 1, options.itemsPerPage, this.filters)
                .then((pagedData: PagedDataRep<Compound>) => {
                    this.items = pagedData.data;
                    this.totalItems = pagedData.total;
                    this.loading = false;
                    return pagedData;
                });
        },

        resetImages: function() {

            const loadedImages: NodeListOf<any> = this.$el.querySelectorAll('img.b-lazy.b-loaded');

            for (let i: number = 0; i < loadedImages.length; i++) {
                loadedImages[i].src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
                loadedImages[i].classList.remove('b-loaded');
            }
        }
    },

    mounted: function() {
        this.reloadTable();
    },

    updated: function() {

        if (this.blazy) {
            this.blazy.destroy();
            this.resetImages();
            this.blazy.revalidate();
        }
        else {
            this.blazy = new Blazy({
                container: 'search-result-table',
                error(elem: HTMLImageElement) {
                    elem.src = DEFAULT_MOLECULE_SVG;
                }
            });
        }
    }
};
