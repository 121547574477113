




































import Vue from 'vue';
import Component from 'vue-class-component';
import Navbar from './navbar/navbar.vue';
import restApi from './rest-api';
import { UserRep } from './rak';
import AboutModal from './about-modal.vue';
import Lightbox from './lightbox.vue';

@Component({ components: { Navbar, AboutModal, Lightbox } })
export default class App extends Vue {

    showAbout: boolean = false;

    mounted() {
        restApi.checkAuthentication()
            .then((userInfo: UserRep) => {
                console.log('>>> Check completed, returned: ' + JSON.stringify(userInfo));
                if (userInfo && userInfo.userName) {
                    this.$store.commit('setUser', userInfo.userName);
                }
            });
    }

    private onCloseLightbox() {
        this.$store.commit('setLightboxImage', null);
    }

    private viewSource() {
        window.open('https://github.com/bobbylight/rak', '_blank');
    }
}
