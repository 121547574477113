































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import deltaToHtml from './delta-to-html';
import UserBadge from './user-badge.vue';
import { BlogPost } from './rak';
import rakUtil from './util';

@Component({ components: { UserBadge } })
export default class BlogPostWidget extends Vue {

    @Prop({ required: true })
    private readonly post: BlogPost;

    get dateString(): string {
        return rakUtil.getDisplayDate(this.post.createDate!);
    }

    get body(): string {
        return deltaToHtml.convert(JSON.parse(this.post.body));
    }
}
