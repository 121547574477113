import { render, staticRenderFns } from "./compound-network.vue?vue&type=template&id=56c74414&"
import script from "./compound-network.vue?vue&type=script&lang=ts&"
export * from "./compound-network.vue?vue&type=script&lang=ts&"
import style0 from "./compound-network.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VList,VListItem,VListItemTitle,VMenu})
