









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class UserBadge extends Vue {

    @Prop({ required: true })
    private readonly user: string;
}
