































import Vue from 'vue';
import Component from 'vue-class-component';
import LazyDropdown from '../lazy-dropdown.vue';
import { Kinase, NanoBretSearchFilter, PagedDataRep } from '../rak';
import ActivityOrKdField from '../search/activity-or-kd-field.vue';

@Component({ components: { LazyDropdown } })
export default class NanoBretSearchFilters extends Vue {

    private readonly ic50ValidationRules: any[] = [

        (value: number) => {
            const max: number = 10000;
            return +value >= 0 && +value <= max || `Must be between 0 and ${max}`;
        }
    ];

    get nanoBretFilters(): NanoBretSearchFilter {
        return this.$store.state.nanoBretFilters;
    }

    private readonly kinaseQueryParams: any = { size: 1000 };

    private kinaseResponseTransformer(response: PagedDataRep<Kinase>): any[] {
        const choices: any[] = response.data.map((kinase: Kinase) => {
            const entrez: string = kinase.discoverxGeneSymbol;
            return { discoverxGeneSymbol: entrez, responseValueField: entrez };
        });
        //choices.unshift({ discoverxGeneSymbol: '', responseValueField: '' });
        return choices;
    }
}
