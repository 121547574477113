


































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import restApi from './rest-api';
import { ErrorResponse, UserRep } from './rak';
import Toaster from './toaster';

const HIDDEN: string = 'close';

@Component
export default class LoginModal extends Vue {

    private user: string = '';
    private password: string = '';
    private readonly passwordVisible: boolean = true;

    @Prop({ required: true })
    private readonly show: boolean;

    get visible() {
        return this.show;
    }

    set visible(newValue: boolean) {
        if (!newValue) {
            this.$emit('close');
        }
    }

    login(): boolean {
        restApi.login(this.user, this.password)
            .then((response: UserRep) => {
                console.log('Login success!');
                this.password = ''; // Clear password, but must keep user for toaster
                this.$store.commit('setUser', this.user);
                this.$emit(HIDDEN);
                Toaster.success(`Welcome back, ${this.user}!`);
                this.user = this.password = '';
            })
            .catch((response: ErrorResponse) => {
                if (response.statusCode === 401) {
                    Toaster.error('You typed the wrong username or password');
                }
                else {
                    Toaster.error('An unknown error occurred');
                }
                console.error(response);
            })
        ;
        return false;
    }

    onCancel() {
        this.$emit(HIDDEN);
    }

    /**
     * When this modal is told to show itself, focus the user name field.
     *
     * @param {boolean} newValue Whether to show or hide ourselves.
     */
    @Watch('show')
    private onShowChanged(newValue: boolean) {
        if (newValue) {
            this.$nextTick(() => {
                (this.$refs.userNameField as HTMLInputElement).focus();
            });
        }
        else {
            this.user = this.password = '';
        }
    }
}
