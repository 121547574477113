






































































import Vue from 'vue';
import Component from 'vue-class-component';
import StatsCompoundTable from './stats-compound-table.vue';
import { ColumnInfo } from './compounds-table.vue';
import SectionHeader from '../header.vue';

@Component({ components: { StatsCompoundTable, SectionHeader } })
export default class Stats extends Vue {

    private incompleteCompoundColumnInfo: ColumnInfo[];
    private compoundsMissingActivityProfilesColumnInfo: ColumnInfo[];
    private compoundsMissingPublicationInfoColumnInfo: ColumnInfo[];
    private hiddenCompoundsColumnInfo: ColumnInfo[];

    private readonly dense: boolean = false;

    created() {

        this.incompleteCompoundColumnInfo = [
            { columnId: 'compoundName', columnName: 'Compound', isCompound: true },
            { columnId: 'chemotype', columnName: 'Chemotype' },
            { columnId: 's10', columnName: 's(10)' },
            { columnId: 'solubility', columnName: 'Solubility' },
            { columnId: 'smiles', columnName: 'SMILES' },
            { columnId: 'source', columnName: 'Source' },
            { columnId: 'primaryReference', columnName: 'Reference' },
            { columnId: 'primaryReferenceUrl', columnName: 'Reference URL' },
            { columnId: 'hidden', columnName: 'Hidden' }
        ];

        this.compoundsMissingActivityProfilesColumnInfo = [
            { columnId: 'compoundName', columnName: 'Compound', isCompound: true },
            { columnId: 'count', columnName: 'Activity Profile Count' }
        ];

        this.compoundsMissingPublicationInfoColumnInfo = this.incompleteCompoundColumnInfo;
        this.hiddenCompoundsColumnInfo = this.incompleteCompoundColumnInfo;
    }
}
